import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";

const WorkHome = () => {
  const [activeCategory, setActiveCategory] = useState("logo");
  const [activelogo, setActivelogo] = useState();

  const handleTabClick = (id: any) => {
    setActiveCategory(id);
  };

  return (
    <div>



        <div
          id="portfolio_website"
          className="grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 justify-center items-center my-3 mt-6 gap-3 w-[100%] h-[90%]"
        >
          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/1-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/2-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/3-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/4-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-center items-center">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/5-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover ">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/6-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover ">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/7-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover ">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/8-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-center items-center">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/9-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>
        </div>

    </div>
  );
};

export default WorkHome;
