import React from 'react'
import { useParams } from 'react-router-dom';
import { LogoDetails } from '../strings/Array';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Header from '../component/Header';
import Footer from '../component/Footer';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

const LogoDetail = () => {
    const { route } = useParams();
    const logo = LogoDetails.find((logo) => logo.route === route);

    if (!logo) {
        return <div>Logo not found</div>;
    }

    return (
        <>
            <Header />
            <div className='2xl:px-28 xl:px-12 lg:px-20 md:px-10 px-6 pt-28'>
                <div>
                    <h2 className='flex gap-1 text-md'><Link to={"/our-portfolio"} className='text-logo'>Portfolio</Link> <MdKeyboardDoubleArrowRight size={23} /> {logo.title}</h2>
                    <h3 className='text-3xl text-black font-semibold pt-6 uppercase'>{logo.heading}</h3>
                    <p className='py-6 text-black'>{logo.para}</p>
                </div>
                {route === 'leftover-toy' && (
                    <div>
                        <div className='md:flex pt-3 gap-3'>
                            <div className='md:w-[100%]'>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='py-3 bg-white'>
                            <ReactPlayer
                                url={logo.video1}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                // style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='md:w-[100%]'>
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='md:w-[100%]'>
                                <img src={logo.img5} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div>
                                <img src={logo.img13} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='md:w-[100%]'>
                                <img src={logo.img7} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div className='md:w-[100%]'>
                                <img src={logo.img9} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div className='md:w-[100%]'>
                                <img src={logo.img11} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img12} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'peak-health' && (
                    <div>
                        <div className='py-3 bg-white'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/peek.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                // style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                            <div >
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                            <div >
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div >
                                <img src={logo.img7} alt="Logo Detail" />
                            </div>
                            <div className='md:w-[78%]'>
                                {/* <div className='pb-3 gap-3'> */}
                                <div >
                                    <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                                    {/* <img src={logo.img9} alt="Logo Detail" className='md:pt-3 pt-3' /> */}
                                </div>
                                <div className='pt-3'>
                                    <img src={logo.img9} alt="Logo Detail" />
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                        {/* <div className='pb-3'>
                            <div>
                                <img src={logo.img13} alt="Logo Detail" />
                            </div>
                        </div> */}
                        {/* <div className='md:flex pb-3 gap-3'>
                            <div className='md:w-[100%]'>
                                <img src={logo.img} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='md:w-[100%]'>
                                <img src={logo.img9} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div className='md:w-[100%]'>
                                <img src={logo.img11} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img12} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div> */}
                    </div>
                )}

                {route === 'hoopfest-2022' && (
                    <div>
                        <div className='py-3 bg-white'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img9} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>

                            <div >
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img11} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>

                            <div >
                                <img src={logo.img12} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img13} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'the-accompaniment-company' && (
                    <div>
                        <div className='py-3 bg-white'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/company.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                // style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div>
                        <div className='pb-3 bg-white'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img2} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img4} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img8} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img9} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img10} alt="Logo Detail" />
                            </div>
                            <div >
                                <img src={logo.img11} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img12} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='py-3 bg-white'>
                            <div>
                                <img src={logo.img13} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img14} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img15} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-12 bg-white'>
                            <div>
                                <img src={logo.img16} alt="Logo Detail" />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'highball-speakeasy' && (
                    <div>
                        {/* <div className='py-3 bg-white'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/company.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                // style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div> */}
                        <div className='py-3 bg-white'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img2} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-3 bg-white'>
                            <div >
                                <img src={logo.img4} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img5} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img7} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='pb-12 bg-white'>
                            <div>
                                <img src={logo.img9} alt="Logo Detail" />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'high-caliber-whiskey' && (
                    <div>
                        {/* <div className='py-3 bg-white'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/company.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                // style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div> */}
                        <div className='py-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div>
                                <img src={logo.img2} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                        </div>
                        {/* <div className='pb-3'>
                            <div >
                                <img src={logo.img4} alt="Logo Detail" />
                            </div>
                        </div> */}
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img4} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img8} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img9} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img10} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img11} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='pb-12 bg-white'>
                            <div>
                                <img src={logo.img12} alt="Logo Detail" />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'swanky-sweets-branding-packaging-design' && (
                    <div>
                        <div className='py-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='md:w-[43%]'>
                                <img src={logo.img2} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img4} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" />
                            </div>
                            <div className="md:w-[64%]">
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'lloyd-industries-packaging-print-web-design' && (
                    <div>
                        <div className='md:flex gap-3 py-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                            <div className='md:w-[91%]'>
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img5} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img7} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img9} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img11} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img12} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='md:w-[45%]'>
                                <img src={logo.img13} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img14} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div >
                                <img src={logo.img15} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img16} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                    </div>
                )}

                {route === '3d-pandaz' && (
                    <div>
                        <div className='py-3 bg-white'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/pandaz-video.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                // style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div>
                        {/* <div className='py-3 bg-white'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div> */}
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/pandaz1-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    // style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                            <div className="md:w-[76%]">
                                <img src={logo.img1} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-3 bg-white'>
                            <div >
                                <img src={logo.img2} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='2xl:w-[76%]'>
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                            <div className='md:pt-0 pt-3'>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/pandaz2-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img4} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img8} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img9} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img11} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/pandaz3-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    // style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                            <div className='md:pt-0 pt-3'>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/pandaz4-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    // style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                        </div>

                        <div className='pb-3 bg-white'>
                            <div>
                                <img src={logo.img12} alt="Logo Detail" />
                            </div>
                        </div>


                        <div className='pb-12 bg-white'>
                            <div>
                                <img src={logo.img13} alt="Logo Detail" />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'cyrus-olearys-pies-branding-packaging-web-design' && (
                    <div>
                        <div className='md:flex gap-3 pt-3'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/cyrus-video.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div>
                        <div className='md:flex gap-3 py-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img5} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img7} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex gap-3 pb-12'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/cyrus1-video.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div>
                    </div>
                )}

                {route === 'north-hill-on-garland-branding-print-design' && (
                    <div>
                        <div className='md:flex gap-3 pt-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex gap-3 py-3'>
                            <div>
                                <img src={logo.img2} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='2xl:w-[64%]'>
                                <img src={logo.img4} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        {/* <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img7} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex gap-3 pb-6'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/cyrus1-video.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div> */}
                    </div>
                )}

                {route === 'elite-construction' && (
                    <div>
                        <div className='md:flex gap-3 pt-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex gap-3 py-3'>
                            <div>
                                <div>
                                    <img src={logo.img2} alt="Logo Detail" />
                                </div>
                                <div>
                                    <img src={logo.img3} alt="Logo Detail" className='pt-3' />
                                </div>
                            </div>
                            <div>
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='2xl:w-[37%]'>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/elite.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                            <div>
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'the-tea-boba-bar-branding-print-design' && (
                    <div>
                        <div className='py-3 bg-white'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/tea-video.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img3} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img5} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img7} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/tea1-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    // style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                            <div className='md:pt-0 pt-3'>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/tea2-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    // style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                            <div className='md:pt-0 pt-3'>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/tea3-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    // style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                            <div className='md:pt-0 pt-3'>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/tea4-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    // style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img9} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img11} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img12} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div >
                                <img src={logo.img13} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img14} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img15} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img16} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                    </div>
                )}

                {route === 'cult-cannabis' && (
                    <div>
                        <div className='py-3 bg-white'>
                            <ReactPlayer
                                url={require('../assets/images/logo/video/cult-video.mp4')}
                                playing
                                loop
                                muted
                                width="100%"
                                height="100%"
                                // style={{ objectFit: 'cover' }}
                                className="w-full"
                            />
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img3} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img5} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div >
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img8} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div className='2xl:w-[73%]'>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/cult1-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    // style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                            <div>
                                <img src={logo.img9} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 bg-white gap-3'>
                            <div>
                                <img src={logo.img10} alt="Logo Detail" />
                            </div>
                            <div >
                                <img src={logo.img11} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div className='2xl:w-[75%] md:pt-0 pt-3'>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/cult2-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                        </div>

                        <div className='md:flex pb-12 bg-white gap-3'>
                            <div>
                                <img src={logo.img12} alt="Logo Detail" />
                            </div>
                            <div>
                                <div className='md:flex gap-3'>
                                    <div>
                                        <img src={logo.img13} alt="Logo Detail" />
                                    </div>
                                    <div>
                                        <img src={logo.img14} alt="Logo Detail" className='md:pt-0 pt-3' />
                                    </div>
                                </div>
                                <div className='pt-3 md:flex gap-3'>
                                    <div>
                                        <img src={logo.img15} alt="Logo Detail" />
                                    </div>
                                    <div>
                                        <img src={logo.img16} alt="Logo Detail" className='md:pt-0 pt-3' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {route === 'strohmaier-design-build' && (
                    <div>
                        <div className='pt-3'>
                            <div>
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/build-video.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                        </div>
                        <div className='md:flex gap-3 py-3'>
                            <div className='w-[100%]'>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div>
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div className='w-[100%]'>
                                <img src={logo.img4} alt="Logo Detail" />
                            </div>

                            <div className='w-[100%]'>
                                <img src={require('../assets/images/logo/build-GIF.gif')} alt="Logo Detail" />
                            </div>

                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img5} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img6} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img8} alt="Logo Detail" />
                            </div>
                            <div className='w-[100%]'>
                                <img src={logo.img9} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-12'>
                            <div>
                                <img src={logo.img10} alt="Logo Detail" />
                            </div>
                        </div>
                    </div>
                )}

                {route === 'aborder-products-inc' && (
                    <div>
                        <div className='md:flex gap-3 pt-3'>
                            <div className='w-[100%]'>
                                <img src={require('../assets/images/logo/product.png')} alt="Logo Detail" />
                            </div>
                            <div className='md:pt-0 pt-3 w-[100%]' >
                                <ReactPlayer
                                    url={require('../assets/images/logo/video/product1.mp4')}
                                    playing
                                    loop
                                    muted
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover' }}
                                    className="w-full"
                                />
                            </div>
                        </div>
                        <div className='md:flex gap-3 py-3'>
                            <div className=''>
                                <img src={require('../assets/images/logo/product-1.png')} alt="Logo Detail" />
                            </div>
                            <div className=''>
                                <img src={require('../assets/images/logo/product-2.png')} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={require('../assets/images/logo/product-3.png')} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={require('../assets/images/logo/product-4.png')} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={require('../assets/images/logo/product-5.png')} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={require('../assets/images/logo/product-6.png')} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={require('../assets/images/logo/product-7.png')} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={require('../assets/images/logo/product-8.png')} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div>
                                <img src={require('../assets/images/logo/product-9.png')} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={require('../assets/images/logo/product-10.png')} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                    </div>
                )}

                {route === 'equinox-gardens' && (
                    <div>
                        <div className='pt-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='pt-3'>
                            <div className=''>
                                <img src={logo.img2} alt="Logo Detail" />
                            </div>
                        </div>

                        <div className='md:flex gap-3 py-3'>
                            <div className=''>
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                            <div className=''>
                                <div className='md:flex gap-3'>
                                    <div >
                                        <img src={logo.img4} alt="Logo Detail" />
                                    </div>
                                    <div>
                                        <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                                    </div>
                                    <div>
                                        <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                                    </div>
                                    {/* <div>
                                        <img src={logo.img7} alt="Logo Detail" className='md:pt-0 pt-3' />
                                    </div> */}
                                </div>
                                <div className='md:pt-0 pt-3'>
                                    <img src={logo.img7} alt="Logo Detail" />
                                </div>
                            </div>
                        </div>

                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img8} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img9} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img11} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img12} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img13} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img14} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img15} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img16} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='md:flex pb-3 gap-3'>
                            <div>
                                <img src={logo.img17} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex pb-12 gap-3'>
                            <div>
                                <img src={logo.img18} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img19} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img20} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img21} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>

                    </div>
                )}

                {route === 'evergreen-estate-elder-law' && (
                    <div>
                        <div className='md:flex gap-3 py-3'>
                            <div>
                                <img src={logo.img1} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img2} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        
                        <div className='md:flex pb-3 gap-3'>
                            <div >
                                <img src={logo.img3} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img4} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img5} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                            <div>
                                <img src={logo.img6} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img7} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img8} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex gap-3 pb-3'>
                            <div>
                                <img src={logo.img9} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img10} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img11} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img12} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img13} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img14} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='md:flex gap-3 pb-3'>
                            <div>
                                <img src={logo.img15} alt="Logo Detail" />
                            </div>
                            <div>
                                <img src={logo.img16} alt="Logo Detail" className='md:pt-0 pt-3' />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img17} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='pb-3'>
                            <div >
                                <img src={logo.img18} alt="Logo Detail" />
                            </div>
                        </div>
                        <div className='pb-12'>
                            <div >
                                <img src={logo.img19} alt="Logo Detail" />
                            </div>
                        </div>
                        
                    </div>
                )}

            </div>
            <Footer />
        </>
    )
}

export default LogoDetail
