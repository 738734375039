import React, { useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { TiTick } from "react-icons/ti";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import Design from "../file/Design";
import Chat from "../file/Chat";
import { useNavigate } from "react-router-dom";
import Contact from "./Contact";
import { CONTACT } from "../strings/CONTACT";
import ScreenContainer from "./ScreenContainer";
import {
  logoPricing,
  printDesignPricing,
  seoServPricing,
  videoAnimPricing,
  webDesginPricing,
} from "../strings/Array";
import PlansSlider from "./sliders/PlansSlider";
import WebDesign from "../file/WebDesign";

const Pricing = () => {
  const [activeCategory, setActiveCategory] = useState("logo");
  const priceTb1Ref: any = useRef(null);
  const navigate = useNavigate();

  const handleTabClick = (id: any) => {
    setActiveCategory(id);
  };

  const scrollToPriceTb1 = () => {
    if (priceTb1Ref.current) {
      priceTb1Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="w-full">
      <Header />

      <div className="bg-primary py-[40px] sm:py-[50px] lg:py-[80px]">
        <ScreenContainer>
          <div className="flex gap-[20px]">
            <div className="w-auto flex flex-col gap-[20px]">
              <div>
                <h5 className="meta-large-para !text-textWhite">
                  Affordable Yet Effective Solutions
                </h5>
                <h1 className="meta-subheading !text-textWhite">
                  <span>Witness an Increase</span> in Your <br /> Business’s
                  <span className="bnr-spn-clr"> ROI</span>{" "}
                </h1>
              </div>
              <h4 className="meta-large-para !text-textWhite">
                We offer comprehensive digital marketing solutions at affordable
                rates to take your business to great heights of online success.
              </h4>
              <ul className="meta-large-para !text-textWhite">
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Over 12 Years of Experience
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Clientele Across 45 Industries
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  100% Satisfaction Guaranteed
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Money-Back Guarantee
                </li>
              </ul>
              <div className="bnr-btn">
                <a
                  onClick={scrollToPriceTb1}
                  className="get-strtd popup-btn cursor-pointer"
                >
                  Get Started
                  <i className="fa fa-power-off" aria-hidden="true"></i>
                </a>
                <a
                  onClick={() => navigate("/contact-us")}
                  className="live-cht chats !text-textWhite"
                >
                  Live Chat{" "}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
              <h6 className="meta-large-para !text-textWhite">
                Implementation process is easy and completly free. Contact us
                for further info
              </h6>
            </div>
            <div className="w-auto">
              <div className="bnr-img-st hidden lg:block">
                <img
                  className="packge-bnr-1"
                  src={require("../assets/images/images-package-bnr-img-1.png")}
                />
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <div
        style={{
          backgroundImage: `url(${require("../assets/home/1.jpg")})`,
        }}
        className="w-full bg-no-repeat bg-cover my-[40px] sm:my-[50px] lg:my-[80px]"
      >
        <ScreenContainer>
          <div className="py-[40px]">
            <div>
              <h1 className="meta-subheading text-white mb-[20px] !text-white">
                Offering Affordable Website & Logo Designs for Global Audience
              </h1>
              <p className="meta-paragraph text-white">
                Candigitals has a variety of services to offer to its customers
                at affordable prices. We deliver bespoke design and branding
                solutions at pocket-friendly rates.
              </p>
              <div className="flex justify-start flex-col items-start w-auto mx-auto gap-3 text-white pt-7">
                <div className="link-area">
                  <a
                    className="hover-effect cursor-pointer"
                    onClick={() => navigate("/contact-us")}
                  >
                    GET STARTED<span className="flash-glow-effect"></span>
                  </a>{" "}
                </div>
                <a
                  href={`tel:${CONTACT.PH_NO}`}
                  className="hover:text-logo meta-paragraph hover:text-white"
                >
                  Call Us: {CONTACT.PH_NO}
                </a>
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>
      <div className="row flex flex-col justify-center items-center">
        <div className="w-full">
          <ScreenContainer>
            <div className="price-nav-tb flex justify-center items-center w-auto">
              <ul
                id="nav-tabs-wrapper"
                className="nav nav-tabs nav-tabs-horizontal flex flex-wrap lg:gap-0 gap-[5px] text-center mx-auto w-auto justify-center items-center cursor-pointer"
              >
                <li className={`${activeCategory === "logo" ? "active" : ""} px-[10px]`}>
                  <a
                    onClick={() => handleTabClick("logo")}
                    id="price_logo"
                    style={{ whiteSpace: "nowrap" }}
                    className="meta-nav-heading"
                  >
                    {" "}
                    Logo Design
                  </a>
                </li>
                <li className={`${activeCategory === "website" ? "active" : ""} px-[10px]`}>
                  <a
                    onClick={() => handleTabClick("website")}
                    id="price_website"
                    style={{ whiteSpace: "nowrap" }}
                    className="meta-nav-heading"
                  >
                    Website Design{" "}
                  </a>
                </li>
                <li className={`${activeCategory === "video" ? "active" : ""} px-[10px]`}>
                  <a
                    onClick={() => handleTabClick("video")}
                    id="price_video"
                    style={{ whiteSpace: "nowrap" }}
                    className="meta-nav-heading"
                  >
                    Video Animation
                  </a>
                </li>
                <li className={`${activeCategory === "seo" ? "active" : ""} px-[10px]`}>
                  <a
                    onClick={() => handleTabClick("seo")}
                    id="price_seo"
                    style={{ whiteSpace: "nowrap" }}
                    className="meta-nav-heading"
                  >
                    SEO Services
                  </a>
                </li>
                <li className={`${activeCategory === "design" ? "active" : ""} px-[10px]`}>
                  <a
                    onClick={() => handleTabClick("design")}
                    id="price_design"
                    style={{ whiteSpace: "nowrap" }}
                    className="meta-nav-heading"
                  >
                    Print Design
                  </a>
                </li>
              </ul>
            </div>
          </ScreenContainer>
        </div>
        <div className="w-full mb-[40px]" ref={priceTb1Ref}>
          <div className="tab-content">
            {activeCategory === "logo" && (
              <div
                role="tabpanel"
                className="tab-pane fade in active"
                id="price-tb1"
              >
                <ScreenContainer>
                  <div className="tab-heading-container mb-[40px]">
                    <h4 className="meta-card-heading">Start Now</h4>
                    <h2 className="meta-subheading ">
                      Your Free Plan Suggesion
                    </h2>
                  </div>
                  <PlansSlider data={logoPricing} />
                  <Design />
                </ScreenContainer>
              </div>
            )}
            {activeCategory === "website" && (
              <div
                role="tabpanel"
                className="tab-pane fade in lg:px-40 md:px-6 flex flex-col"
                id="price-tb2"
              >
                <ScreenContainer>
                  <div className="tab-heading-container mb-[40px]">
                    <h4 className="meta-card-heading">Start Now</h4>
                    <h2 className="meta-subheading ">
                      Your Free Plan Suggesion
                    </h2>
                  </div>
                  <PlansSlider data={webDesginPricing} />
                  <WebDesign />
                </ScreenContainer>
              </div>
            )}
            {activeCategory === "video" && (
              <div
                role="tabpanel"
                className="tab-pane fade in lg:px-40 md:px-6"
                id="price-tb3"
              >
                <ScreenContainer>
                  <div className="tab-heading-container mb-[40px]">
                    <h4 className="meta-card-heading">Start Now</h4>
                    <h2 className="meta-subheading ">
                      Your Free Plan Suggesion
                    </h2>
                  </div>
                  <PlansSlider data={videoAnimPricing} />
                </ScreenContainer>
              </div>
            )}
            {activeCategory === "seo" && (
              <div
                role="tabpanel"
                className="tab-pane fade in lg:px-40 md:px-6"
                id="price-tb4"
              >
                <ScreenContainer>
                  <div className="tab-heading-container mb-[40px]">
                    <h4 className="meta-card-heading">Start Now</h4>
                    <h2 className="meta-subheading ">
                      Your Free Plan Suggesion
                    </h2>
                  </div>
                  <PlansSlider data={seoServPricing} />
                </ScreenContainer>
              </div>
            )}
            {activeCategory === "design" && (
              <div
                role="tabpanel"
                className="tab-pane fade in lg:px-40"
                id="price-tb5"
              >
                <ScreenContainer>
                  <div className="tab-heading-container mb-[40px]">
                    <h4 className="meta-card-heading">Start Now</h4>
                    <h2 className="meta-subheading ">
                      Your Free Plan Suggesion
                    </h2>
                  </div>
                  <PlansSlider data={printDesignPricing} />
                </ScreenContainer>
              </div>
            )}
          </div>
        </div>
      </div>
      <Chat />
      <Footer />
    </div>
  );
};

export default Pricing;
