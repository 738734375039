import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <div className="flex flex-col min-h-screen">
        <div className="fixed top-0 z-10">
          <Header />
        </div>
        <div className="max-w-4xl mx-auto pt-[130px] p-6">

          <h1 className="text-3xl font-bold mb-6 flex justify-center ">Privacy Policy</h1>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Privacy Policy Statement</h2>
            <p className="mb-4">
              At METANADO, we take the security and privacy of our clients' personal information very seriously. Rest assured, we only gather the necessary details needed to fulfill your order. Your information is always kept confidential and will never be disclosed to anyone outside of our company without your consent.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Information Collection</h2>
            <p className="mb-4">
              We collect clients’ information via Email, Phone, Contact Us and Order Form and it is protected as per privacy policy set by METANADO. Clients' information is never disclosed, shared or sold to any third-party services.
            </p>
            <p className="mb-4">
              Initially, the personal information we require is about clients’ name, company’s name, billing address, email address, secondary email, phone and fax numbers, order details, and place of residence, some of which is optional. Along with this, we need information in the creative brief to start working on your order.
            </p>
            <p className="mb-4">
              In addition, gathering data involves feedback on complaints, praise, and reviews regarding the product. Technical information which is collected and recorded includes your IP address, browser version, operating system, and the date and time when you visit. Google Analytics or some similar tool or service is also used for collecting this data.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Usage Of Collected Information</h2>
            <p className="mb-4">
              First of all, the information we collect helps us to understand exactly what product you need and what special things you require in it. Secondly, the billing information is inevitable for the payment process.
            </p>
            <p className="mb-4">
              The email addresses are used for communication about order status, comments, and opinions and delivery of the finalized product.
            </p>
            <p className="mb-4">
              Additionally, we will keep you informed through email about website updates, special promotions, new product/service launches, and industry news.
            </p>
            <p className="mb-4">
              This information is valuable for enhancing our marketing tactics and keeping track of our best-selling items.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Privacy Of Payments</h2>
            <p className="mb-4">
              To make sure the practice of the best privacy for payments, we offer acceptance from multiple payment options. The payment processor where the clients put their billing information uses Secure Sockets Layer (SSL) which is indicated in the domain as https://. Rest assured, we guarantee that there are no problems with the privacy of your payment process and billing information.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Confidentiality</h2>
            <p className="mb-4">
              Confidentiality is surely a serious concern and we are fully aware of the fact and this is why we never compromise over the confidentiality of our clients’ information.
            </p>
            <p className="mb-4">
              We use your personal information in order to keep in touch with you so that we can have smooth communication about the order process. Therefore, your entire information like name, billing address, e-mail address, telephone number, fax, etc. is treated as confidential and saved at a secure location which is accessible by the designated members only ensuring that it stays safe and there is no threat of its being stolen or hacked.
            </p>
            <p className="mb-4">
              We reassure that your personal information will never be released to any third party in any circumstances until you allow or we are forced by law.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Amendments</h2>
            <p className="mb-4">
              If necessary, METANADO might update its privacy policy to enhance security measures. However, any changes regarding the security of personal information will be announced in advance, including the effective date of the updated or amended privacy policies.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Conditions Of Information Disclosure</h2>
            <p className="mb-4">
              In the first place, it is our top priority to avoid the information disclosure. However, the information disclosure can only take place if we are to abide by the country law for judicial proceeding, court order, or any other legal procedure.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="mb-4">
              If there are any questions or uncertainties about our privacy policies, please feel free to reach out to us using our contact form.
            </p>
          </section>

          
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
