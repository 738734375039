import React from "react";
import arrow from "../assets/images/arrow_1.svg";
import ScreenContainer from "./ScreenContainer";
const TitleWithSubTitleDesc = ({ data, bgColor = "#F1F1F1", mainDivClasses="", titleColor="black", descColor="#4b4b4b" }: any) => {
  return (
    <div className={`bg-[${bgColor}] ${mainDivClasses}`}>
      <ScreenContainer>
        <div className="flex flex-col justify-center items-center gap-[10px]">
          <div className="flex justify-center items-center">
            <img src={arrow} alt="Arrow" className="w-[5%]" />
            <h4 className="meta-paragraph" style={{textTransform:"uppercase"}}>{data?.subTitle}</h4>
            <img
              src={arrow}
              alt="Arrow"
              className="w-[5%] transform rotate-180"
            />
          </div>
          <h2 className="meta-subheading !text-center" style={{color:`${titleColor}`, textTransform:"capitalize"}}>
            {data?.mainTitle}
          </h2>
          <p className="meta-large-para !text-center w-auto md:w-[90%] lg:w-[60%]" style={{color:`${descColor}`, fontWeight:"400"}}>
            {data?.description}
          </p>
        </div>
      </ScreenContainer>
    </div>
  );
};

export default TitleWithSubTitleDesc;
