import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Work from '../file/Work'
import ContactForm from '../file/ContactForm'
import Chat from '../file/Chat'
import ScreenContainer from './ScreenContainer'
import TitleWithSubTitleDesc from './TitleWithSubTitleDesc'
const Portfolio = () => {
    return (
        <div className='w-auto '>
            <Header />
            <div className='py-[40px] sm:py-[50px] lg:py-[80px]'>

            <ScreenContainer>
            <TitleWithSubTitleDesc data={{mainTitle:'A snippet of our portfolio from our database.',subTitle:'OUR Portfolio',description:'Candigitals have provided value-based services throughout the world. Over the years, we have served more than 5000+ clients throughout the world.'}} bgColor={'white'} />

                {/* <h2 className='font-bold xl:text-5xl lg:text-3xl text-xl  text-center my-2 mx-3 text-[#5f13f5]'>A snippet of our portfolio from our database.</h2>
                <p className='md:text-2xl text-lg text-center w-auto md:w-[80%] lg:w-[60%] text-zinc-600 md:mb-8 mb-4'>Candigitals have provided value-based services throughout the world. Over the years, we have served more than 5000+ clients throughout the world.</p> */}
              
            <Work />
            </ScreenContainer>
                </div>
            <div className='mb-10'>
            <ContactForm/>
            </div>
            <Chat/>
            <Footer />
        </div>
    )
}

export default Portfolio
