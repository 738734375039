import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';

const TermsAndServices = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <div className="flex flex-col min-h-screen">
        <div className="fixed top-0 z-10">
          <Header />
        </div>
        <div className="max-w-4xl mx-auto pt-[130px] p-6">
          <section className="mb-8">
            <h2 className="text-3xl font-bold mb-6 flex justify-center">Terms and Conditions</h2>
            <h3 className="text-2xl font-semibold mb-4">Revision Policy</h3>
            <p className="mb-4">
              The number of revisions you can request depends on the package you choose. We are committed to giving you the best service and will keep revising until you are satisfied. You won't have to pay extra if the design concepts remain the same. Your revised logo will be ready in 48 hours, while website design revisions will be done in 24 hours and web development revisions will take between 24 to 48 hours, depending on the complexity.
            </p>
          </section>

          {/* New Content Section */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">New Order Turnaround Time</h2>
            <p className="mb-4">
              Turnaround time is the period during which your design concepts are delivered to your account area upon purchasing a package. For new logo orders, the turnaround time ranges from 24 to 48 hours. However, for packages containing four or more logo concepts, the turnaround time is at least 48 hours after order confirmation and complete brief submission. Additionally, for new website designs (home/index, including revamp designs), the minimum turnaround time is 48 hours after order confirmation and complete brief submission. It is important to note that the turnaround time for website development (static website with 5 to 7 webpages) is between three to five business days. Orders placed on Friday or Saturday will have the delivery time starting from Monday.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Teamwork (Project Management)</h2>
            <p className="mb-4">
              Teamwork is a project management tool that enhances collaboration, visibility, accountability, and ultimately results for our teams' projects. Project management goes beyond completing tasks; it's about delivering impactful results for your business. Our platform makes it simple for everyone to track their projects, communicate with their project manager, and plan the next steps from anywhere.
            </p>
            <p className="mb-4">
              Once a project is signed up with us, the client will receive an email invitation to the Teamwork portal. They must accept the invite and set a new password to connect with their project manager and facilitate project completion. After accepting the invitation, the client can log in to the Teamwork Portal.
            </p>
            <p className="mb-4">
              If you have any queries with the teamwork signup/invitation, please contact us at <a href="mailto:info@metanado.com" className="text-blue-600">info@metanado.com</a>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Refund Policy</h2>
            <p className="mb-4">
              METANADO’s refund policy will be void if:
            </p>
            <ul className="list-disc ml-6 mb-4">
              <li>You have chosen an All-in-one package.</li>
              <li>The primary design concept has been approved.</li>
              <li>You have demanded revisions.</li>
              <li>You haven't accepted the Teamwork invitation.</li>
              <li>The cancellation has been made due to reasons non-related to the company.</li>
              <li>The company has not been contacted for more than 2 weeks of project.</li>
              <li>Company’s policies, or policy, have been violated.</li>
              <li>Other company or designer has been approached for the same project.</li>
              <li>The creative brief is incomplete/ lacking in required information.</li>
              <li>A complete design change has been demanded.</li>
              <li>You are not entitled to any refund until you have availed at least 2 revisions.</li>
              <li>The claim has crossed the given ‘request for refund’ time span.</li>
              <li>The business is closing or changing name or business.</li>
              <li>The project has been archived because of incomplete order brief or upon client's notice.</li>
              <li>Reasons such as ‘change of mind’, ‘disagreement with partner’ or other reasons that do not pertain to the service will not be subject to refund under any circumstances.</li>
              <li>If a client subscribes for a service bundle and happens to be dissatisfied with a particular service, refund will only be applicable on that particular service and ‘not’ the entire bundle.</li>
              <li>No refund can be claimed/processed once after the final files have been delivered to you following your request.</li>
              <li>Refund and revision explicitly do not apply on items offered free.</li>
              <li>The client will not be entitled to any refunds after 30 days, from the date of purchase.</li>
              <li>Services including but not limited to social media, SEO, Domain Registration, SSL and Web Hosting are not entitled to refund under any circumstances.</li>
              <li>In case of websites, refunds will not be entertained once the client has approved the design and the website is sent for development.</li>
              <li>Note: METANADO holds all rights to reject any project or cancel the contract whenever it deems necessary. After the refund, you will not have any rights to use the designs for any purpose; they will be the sole property of METANADO. The company will be the rightful owner of the designs.</li>
              <li>Should a client transfer the website to another third-party company without prior notification to the company (METANADO), the company will not be responsible for any development issues.</li>
            </ul>
            <p className="mb-4">
              Claim Your Refund:
            </p>
            <ul className="list-disc ml-6 mb-4">
              <li>Chat. Click <a href="/chat" className="text-blue-600">here</a> to start Live chat now</li>
              <li>Sending us an Email.</li>
              <li>As soon as we receive your refund request, we will respond to it at our earliest, once the required analysis is completed, we will initiate the process.</li>
              <li>After you have received your refund, you will not have any rights to any designs submitted by METANADO; the information will be submitted to the Copyright Acquisition of the Government Copyright Agencies to maintain legality.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Quality Assurance Policy</h2>
            <p className="mb-4">
              We do our best to meet your requirements and our designers do their best to fulfill your expectations.
            </p>
            <p className="mb-4">
              Please be informed that METANADO uses virtual operations to provide expert-level digital solutions to all its clients. Our project managers are strictly instructed to cater to clients and provide maximum support during working hours, including emails, phone calls, and virtual meetings.
            </p>
            <p className="mb-4">
              We believe in providing the best designs and each of our designs is well researched and well crafted.
            </p>
            <p className="mb-4">
              100% SATISFACTION GUARANTEE: Our unlimited revisions policy is to make sure that you are 100% satisfied. We aim at exceeding your expectations and strive to accomplish it. We do not stop our revisions until you are completely satisfied with your design (number of revisions will be according to your package).
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Delivery Policy</h2>
            <p className="mb-4">
              The complete order will be sent to the mentioned account on the date stated on Order Confirmation as well as a confirmation email will also be sent. The turnaround time will be according to the package, the minimum time required is 2 business days. In case of urgent order, contact our customer support team.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Record Maintenance</h2>
            <p className="mb-4">
              METANADO keeps the records of finalized designs so that in case of any misplaced order, you will be provided the exact file.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Inactive Accounts Policy</h2>
            <p className="mb-4">
              METANADO shall archive any website project considering that it is under development phase after 90 working days of inactivity and there will be an additional cost involved for the re-activation of the project. This happens to ensure that our demo website servers are used efficiently.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Customer Support</h2>
            <p className="mb-4">
              Our customer support is present 24/7 to answer all of your concerns and queries, our team will answer your concerns anytime and every time.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Communication Policy</h2>
            <p className="mb-4">
              METANADO will not be responsible for any communication done via any other platform than <a href="mailto:info@metanado.com" className="text-blue-600">info@metanado.com</a> or any numbers not provided by us or given on our official website.
            </p>
            <p className="mb-4">
              METANADO has imposed strict guidelines for the interaction between our employees and clients. We do not allow our personnel to connect with our customers outside of the business relationship for various reasons that go against our company's interests.
            </p>
            <p className="mb-4">
              We are not responsible for any damages caused due to other contact details not provided by us. We take full responsibility of all the information provided through our official domains.
            </p>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TermsAndServices ;
