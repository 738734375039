import React from "react";
import {
  FaBehance,
  FaDribbble,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import bs_logo from "../assets/images/main-logo.png";
import bs_logo1 from "../assets/images/payment-links.png";
import { Link } from "react-router-dom";
import ScreenContainer from "./ScreenContainer";
const Footer = () => {
  return (
    <footer className="bg-black">
      <ScreenContainer>
        <div className="flex flex-col gap-[30px] md:py-[40px] py-[30px]">
          <div className="">
            <Link to="/">
              <img
                src={bs_logo}
                className="w-[150px] h-[30px] md:mx-auto "
                alt="logo"
              />
            </Link>
          </div>
          <div className="text-white flex flex-row gap-[22px] md:justify-center justify-start">
            <Link to="https://web.facebook.com/metanado?_rdc=1&_rdr">
              <h1 className="hover:text-blue-600 duration-300 transform hover:scale-100">
                <FaFacebook size={25} />
              </h1>
            </Link>
            <Link to="https://www.youtube.com/channel/UCD85u7RTpFj2OrUUdJcZ1qA">
              <h1 className="hover:text-red-500 duration-300 transform hover:scale-100">
                <FaYoutube size={25} />
              </h1>
            </Link>
            <Link to="https://www.instagram.com/metanado/?igsh=MXhvMXVmMnVobDlwMw%3D%3D">
              <h1 className="hover:text-purple-900 duration-300 transform hover:scale-100">
                <FaInstagram size={25} />
              </h1>
            </Link>
          </div>
          <div className="footer-nav">
            <ul className="footer-links text-white flex md:flex-row flex-col gap-[12px] justify-center">
              <li>
                <a href="/">
                  <span className="menu-h-effect shadow-sm">Home</span>
                </a>
              </li>
              <li>
                <a href="logo-and-branding-identity">
                  <span className="menu-h-effect shadow-sm">Logo &amp; Branding</span>
                </a>
              </li>
              <li>
                <a href="web-design-services">
                  <span className="menu-h-effect shadow-sm">Web Design</span>
                </a>
              </li>
              <li>
                <a href="explainer-animation-videos">
                  <span className="menu-h-effect shadow-sm">Video Animation</span>
                </a>
              </li>
              <li>
                <a href="marketing">
                  <span className="menu-h-effect shadow-sm">Digital Marketing</span>
                </a>
              </li>
              <li>
                <a href="portfolio">
                  <span className="menu-h-effect shadow-sm">Portfolio</span>
                </a>
              </li>
              <li>
                <a href="about-us">
                  <span className="menu-h-effect shadow-sm">About</span>
                </a>
              </li>
              <li>
                <a href="contact-us">
                  <span className="menu-h-effect shadow-sm">Contact</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="group-footer-imgs">
            <img src={bs_logo1} alt="logo" className="mx-auto" />
          </div>
        </div>
      </ScreenContainer>
      <div className="bg-zinc-800">
        <ScreenContainer>
          <div className="flex md:flex-row flex-col-reverse md:justify-between justify-start items-center py-[1rem] text-center gap-[10px] md:gap-0">
            <div className="text-white">
              &copy; Metanado 2024. All rights reserved
            </div>
            <div className="flex justify-center items-center gap-3">
              <ul className="footer-bottom-links">
                <li>
                  <Link to="/TermsAndServices">
                    <span className="menu-h-effect shadow-sm">Terms of Services</span>
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    <span className="menu-h-effect shadow-sm">Privacy Policy</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </ScreenContainer>
      </div>
    </footer>
  );
};

const NavLink = ({ to, label, onClick }: any) => (
  <Link
    to={to}
    onClick={onClick}
    className={`block p-2 hover:text-green-700 transition-all duration-300 `}
  >
    <h1>{label}</h1>
  </Link>
);

export default Footer;
