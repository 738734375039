import React, { useState } from "react";
import logo10 from "../assets/images/logo-1-1.png";
import logo11 from "../assets/images/logo-2-1.png";
import logo12 from "../assets/images/logo-3-1.png";
import logo13 from "../assets/images/logo-4-1.png";
import logo14 from "../assets/images/logo-5-1.jpg";
import logo15 from "../assets/images/logo-6-1.png";
import logo16 from "../assets/images/logo-7-1.png";
import logo17 from "../assets/images/logo-8-1.png";
import logo18 from "../assets/images/logo-9-1.png";
import { FaPlay } from "react-icons/fa";

const WorkHome = () => {
  const [activeCategory, setActiveCategory] = useState("logo");
  const [activelogo, setActivelogo] = useState();

  const handleTabClick = (id: any) => {
    setActiveCategory(id);
  };

  return (
    <div>

      
        <div
          id="portfolio_brand"
          className="grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 justify-center items-center my-3 mt-6 gap-3 w-[100%] h-[90%] py-[40px] sm:py-[50px] lg:py-[80px]"
        >
          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-1.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-1-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 ml-16 lg:pt-12 lg:pb-12 pt-12 pb-8 px-8 lg:w-[45%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[90%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-2.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-2-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-16 ml-16 lg:pb-0 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[60%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-3.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-3-1.jpg")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-6 ml-12 lg:pb-16 pt-12 pb-8 px-8 lg:w-[40%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[80%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-4.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-4-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-6 xl:ml-40 lg:ml-28 md:ml-20 ml-28 lg:pb-16 pt-12 pb-8 px-8 lg:w-[35%] w-[35%] h-[30%] md:w-[70%] md:h-[100%] lg:h-[30%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-5.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-5-1.jpg")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 mr-12 hover:scale-110 lg:pt-16 lg:pb-10 pt-12 pb-8 px-8 lg:w-[45%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[100%] flex justify-start items-start"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-6.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover "
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-6-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-16 lg:pb-2 pt-12 pb-8 px-8 lg:w-[75%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-7.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-end items-center"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-7-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-48 lg:pb-0 pt-12 pb-8 px-8 lg:w-[60%] w-[70%] h-[100%] md:w-[70%] md:h-[70%] lg:h-[85%] flex justify-end items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-8.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-end items-center"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-8-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-48 lg:pb-0 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[90%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("./assets/images/branding-img-9.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-end items-center"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("./assets/images/branding-img-9-1.jpg")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-20 lg:pb-12 pt-12 pb-8 px-8 lg:w-[55%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[50%] flex justify-center items-center"
              />
            </div>
          </div>
        </div>
      

    </div>
  );
};

export default WorkHome;
