import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import { FaCircleArrowRight, FaCircleArrowLeft } from "react-icons/fa6";
import TestimonialCard from "../TestimonialCard";
import { testimonial } from "../../strings/Array";

const TestimonialSlider = ({ data }: any) => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full relative">
      <div className="simple-slider-nav-btns w-[102%] absolute left-[-12px] flex flex-row justify-between z-10 top-[40%]">
        <button className="simple-prev-btn w-[28px] h-[28px]">
          <FaCircleArrowLeft className="w-full h-full text-[#643db4]" />
        </button>
        <button className="simple-next-btn w-[28px] h-[28px] ">
          <FaCircleArrowRight className="w-full h-full text-[#643db4]" />
        </button>
      </div>
      <div className="simple-swiper-container">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={30}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          slidesPerView={1}
          navigation={{
            nextEl: ".simple-next-btn",
            prevEl: ".simple-prev-btn",
          }}
          loop={true}
        >
          {testimonial?.map((plan: any, index: number) => {
            // const { para, image, name, post } = plan;
            return (
              <SwiperSlide key={index}>
               <TestimonialCard data={plan} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default TestimonialSlider;
